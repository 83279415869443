function processErrors(prependText, result, curErrorMsg) {
  let errorMsg = curErrorMsg;
  for(let i=0; i < result.failed.length; i++) {
    const failKey = result.failed[i];
    if(result[failKey] && result[failKey].approved == false) {
      const errors = result[failKey].errors;
      for(let j=0; j < errors.length; j++) {
        if(errorMsg && errorMsg.length > 0) errorMsg += '<br/>';
        errorMsg += prependText + ' ' + errors[j];
      }
    }
  }
  return errorMsg;
}

function loadMessages(msgs) {
  if(!msgs || msgs.length === 0) return '';

  let htmlMsg = '';
  for(let i=0; i < msgs.length; i++) {
    if(htmlMsg.length > 0) htmlMsg += '<br>';
    htmlMsg += msgs[i];
  }
  return htmlMsg;
}

function buildPasswordStrengthFeedback(result) {
  let feedback = result.feedback && result.feedback.warning ? result.feedback.warning : '';
  if(result.feedback && result.feedback.suggestions) {
    if(feedback.length > 0) feedback += '.'
    for(let i=0; i < result.feedback.suggestions.length; i++) {
      if(feedback.length > 0) feedback += '  ';
      feedback += result.feedback.suggestions[i];
    }
  }
  return sanitizeUserInput(feedback);
}

/** XSS preventation - use sanitize function on user-generated fields that are sent into html directly **/
function sanitizeUserInput(string) {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    "/": '&#x2F;',
    "`": '&grave;'
  };
  const reg = /[&<>"'/`]/ig;
  return string.replace(reg, (match)=>(map[match]));
}